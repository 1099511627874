import React from 'react';
import './Marquee.css';
import duck from '../assets/duck.svg';

const Marquee: React.FC = () => {
    return (

        <div className="marquee-container relative z-10 font-halo-dek border-t border-b border-yellow-950 rotate-[-3deg] overflow-hidden whitespace-nowrap bg-red-50 py-2">
            <div className="marquee-content inline-flex animate-marquee space-x-8">
                {[...Array(20)].map((_, index) => (
                    <span key={index} className="text-lg tracking-wide  text-black flex items-center">
            <span className="mr-6 w-4 h-4 tracking-wide"></span>$ANIMAL
          </span>
                ))}
            </div>
        </div>


    )
        ;
};

export default Marquee;
